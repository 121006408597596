import ApiUrlsADFS from "@/services/apiADFS/apiADFS.urls";
import Vue from "vue";

export default {
  dataToken: {
    get(options) {
      return Vue.axios.post(ApiUrlsADFS.dataToken.get, options);
    },
    getRefresh(options) {
      return Vue.axios.get(ApiUrlsADFS.dataToken.getRefresh + options);
    }
  },

  account: {
    login(params) {
      return ApiUrlsADFS.account.login + params;
    },
    logout(params) {
      return ApiUrlsADFS.account.logout + params;
    },
  },
};
