const userEmailRule = function (text) {
  return Boolean(text) || "Необходимо выбрать пользователя";
};

const userRoleRule = function (text) {
  return Boolean(text) || "Необходимо указать роль";
};

const userContainerRule = function (text) {
  return Boolean(text) || "Необходимо указать контейнер";
};

const delegationTagRule = function (text) {
  return Boolean(text) || "Необходимо указать тег";
};

const delegationUserRule = function (text) {
  return Boolean(text) || "Необходимо выбрать пользователя";
};

export default {
  userRules: {
    emailRules: [userEmailRule],
    roleRules: [userRoleRule],
    containerRules: [userContainerRule],
  },
  delegationRules: {
    tagRules: [delegationTagRule],
    userRules: [delegationUserRule],
  },
};
