<template>
  <v-container>
    
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Фоновые операции</h2>
      </v-col>
    </v-row>

    <state-container ref="main">
      <v-container class="full-container">
        <v-row class="mt-10">
          <v-col style="text-align: start">
            <h3>Активные</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <custom-table :items="tasks.active"
                          :headers="headersActive"
                          :items-per-page="5">

              <!-- eslint-disable-next-line -->
              <template v-slot:item.createdAt="{ item }">
                {{ item.createdAt | dateTime }}
              </template>

            </custom-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col style="text-align: start">
            <h3>Завершенные</h3>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <custom-table :items="tasks.completed"
                          :headers="headersCompleted">

              <!-- eslint-disable-next-line -->
              <template v-slot:item.finishedOn="{ item }">
                {{item.finishedOn | dateTime }}
              </template>

            </custom-table>
          </v-col>
        </v-row>

      </v-container>
    </state-container>

    <overlay :value="loading" />

  </v-container>
</template>

<script>

import ApiService from "@/services/api"

import Filters from "@/helpers/ui/filters"

import Overlay from "@/components/Overlay.vue"
import StateContainer from "@/components/StateContainer.vue"
import Table from "@/components/Table.vue"

import StateMixins from "@/mixins/state"

export default {
  mixins: [StateMixins],

  data() {
    return {
      loading: true,
      headersActive: [
        { text: "Описание", value: "description" },
        { text: "Статус", value: "status" },
        { text: "Создана", value: "createdAt" }
      ],
      headersCompleted: [
        { text: "Описание", value: "description" },
        { text: "Завершена", value: "finishedOn" }
      ],
      tasks: []
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      ApiService.tasks.getAll()
        .then(response => {
          this.tasks = response.data;
          if (!response.data || (!response.data.active.length && !response.data.completed.length))
            this.setNoDataState("main");
          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        });
    }
  },

  filters: {
    dateTime: Filters.dateTime
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table
  }
};
</script>
