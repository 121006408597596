<template>
  <v-row>
    <v-col class="search-panel">
      <v-text-field v-model="searchText"
                    :placeholder="placeholder"
                    outlined
                    dense
                    clearable
                    class="mr-5"
                    @keydown="onKeyDown" />
      <v-btn color="primary"
             class="mr-5"
             @click="onSearchClicked">
        Поиск
      </v-btn>
      <v-btn color="primary"
             v-show="inSearch"
             @click="onClearSearchClicked">
        Сброс
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import "@/assets/styles/components.css"

export default {
  
  props: {
    placeholder: {
      type: String,
      default: "Поиск по названию"
    }
  },

  data() {
    return {
      searchText: null,
      inSearch: false
    }
  },

  methods: {
    onSearchClicked() {
      if (this.searchText) {
        this.$emit("search", this.searchText);
        this.inSearch = true;
      }
    },
    onClearSearchClicked() {
      this.$emit("search", null);
      this.searchText = null;
      this.inSearch = false;
    },
    onKeyDown(e) {
      if (e.code == "Enter")
        this.onSearchClicked();
    }
  }

}
</script>