export default class StorageSettingObject {

  days = 0;

  constructor(storage) {
    if (storage) {
      this.days = storage.days;
    }
  }

  toRequest() {
    return {
      Days: Number(this.days)
    }
  }
}