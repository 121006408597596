const BASE_URL = process.env.VUE_APP_BASE_URL;

export default {
  containers: {
    base: BASE_URL + "/Containers",

    createKey: BASE_URL + "/Containers/Keys",
    create: BASE_URL + "/Containers",
    update: BASE_URL + "/Containers",
    delete: BASE_URL + "/Containers",

    getNames: BASE_URL + "/Containers/Names",
  },

  files: {
    base: BASE_URL + "/Files",

    update: BASE_URL + "/Files",
    delete: BASE_URL + "/Files",
  },

  tags: {
    base: BASE_URL + "/Tags",

    create: BASE_URL + "/Tags",
    update: BASE_URL + "/Tags",
    delete: BASE_URL + "/Tags",

    getMainTagsNames: BASE_URL + "/Tags/MainTags/Names",
    getContainerInfoTagsNames: BASE_URL + "/Tags",
  },

  users: {
    base: BASE_URL + "/Users",

    get: BASE_URL + "/Users",
    getCurrentUser: BASE_URL + "/Users/Current",
    create: BASE_URL + "/Users",
    update: BASE_URL + "/Users",
    delete: BASE_URL + "/Users",

    getNames: BASE_URL + "/Users/Names",
  },

  tasks: {
    getAll: BASE_URL + "/Jobs",
  },

  settings: {
    getAll: BASE_URL + "/Settings",
    getAdmin: BASE_URL + "/Settings/Admin",

    updateArchive: BASE_URL + "/Settings/Archive",
    updateStorage: BASE_URL + "/Settings/Storage",
    updateTimezone: BASE_URL + "/Settings/Timezone",
    updateAccess: BASE_URL + "/Settings/Access",
  },

  journal: {
    base: BASE_URL + "/Journal",
  },
};
