const ADFS_BASE_URL = process.env.VUE_APP_ADFS_BASE_URL;

export default {
  dataToken: {
    get: ADFS_BASE_URL + "/token",
    getRefresh: ADFS_BASE_URL + "/authorize?",
  },

  account: {
    login: ADFS_BASE_URL + "/authorize?",
    logout: ADFS_BASE_URL + "/logout?",
  },
};
