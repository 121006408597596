import ApiUrls from "./api/api.urls"
import ApiBase from "./api/api.base"

export default {

  containers: {
    createKey() {
      return ApiBase.post(ApiUrls.containers.createKey);
    },
    getAll() {
      return ApiBase.get(ApiUrls.containers.base);
    },
    get(name) {
      return ApiBase.get(`${ApiUrls.containers.base}/${name}`);
    },
    create(body) {
      return ApiBase.post(ApiUrls.containers.create, body);
    },
    update(body) {
      return ApiBase.put(ApiUrls.containers.update, body);
    },
    delete(container) {
      return ApiBase.delete(`${ApiUrls.containers.delete}/${container}`);
    },

    getNames() {
      return ApiBase.get(ApiUrls.containers.getNames);
    }
  },

  files: {
    getContainerFiles(container, token, search) {
      let url = `${ApiUrls.files.base}/${container}?`;
      if (search)
        url += `&search=${search}`;

      return ApiBase.get(url);
    },
    getContainerFile(container, fileId) {
      return ApiBase.get(`${ApiUrls.files.base}/${container}/${fileId}`);
    },
    update(container, body) {
      return ApiBase.put(`${ApiUrls.files.update}/${container}`, body);
    },
    delete(container, id) {
      return ApiBase.delete(`${ApiUrls.files.update}/${container}/${id}`);
    }
  },

  tags: {
    getContainerTags(container, token, search) {
      let url = `${ApiUrls.tags.base}/${container}?`;
      if (search)
        url += `&search=${search}`;

      return ApiBase.get(url);
    },
    getContainerTag(container, tagId) {
      return ApiBase.get(`${ApiUrls.tags.base}/${container}/${tagId}`);
    },
    create(container, body) {
      return ApiBase.post(`${ApiUrls.tags.create}/${container}`, body);
    },
    update(container, body) {
      return ApiBase.put(`${ApiUrls.tags.update}/${container}`, body);
    },
    delete(container, id) {
      return ApiBase.delete(`${ApiUrls.tags.update}/${container}/${id}`);
    },

    getMainTagsNames() {
      return ApiBase.get(ApiUrls.tags.getMainTagsNames);
    },
    getContainerMainTagsNames(container) {
      return ApiBase.get(`${ApiUrls.tags.base}/${container}/MainTags/Names`);
    },
    getContainerInfoTagsNames(container) {
      return ApiBase.get(`${ApiUrls.tags.getContainerInfoTagsNames}/${container}/InfoTags/Names`);
    }
  },

  users: {
    getAll() {
      return ApiBase.get(ApiUrls.users.get);
    },
    getForContainer(container) {
      return ApiBase.get(`${ApiUrls.users.get}?container=${container}`);
    },
    get(email) {
      return ApiBase.get(`${ApiUrls.users.get}/${email}`);
    },
    getCurrentUser() {
      return ApiBase.get(`${ApiUrls.users.getCurrentUser}`);
    },
    create(body) {
      return ApiBase.post(ApiUrls.users.create, body);
    },
    createForContainer(container, body) {
      return ApiBase.post(`${ApiUrls.users.create}/${container}`, body);
    },
    update(body) {
      return ApiBase.put(ApiUrls.users.update, body);
    },
    delete(email) {
      return ApiBase.delete(`${ApiUrls.users.delete}/${email}`);
    },
    restore(email) {
      return ApiBase.put(`${ApiUrls.users.base}/${email}`);
    },

    getNames() {
      return ApiBase.get(ApiUrls.users.getNames);
    },
    getNamesForContainer(container, notAdded, roles) {
      let url = `${ApiUrls.users.getNames}`;
      if (container) {
        url += `?container=${container}`;
      }
      if (roles) {
        roles.forEach(role => {
          url += `&roles=${role}`;
        });
      }
      if (notAdded) {
        url += `&notAdded=${notAdded}`;
      }

      return ApiBase.get(url);
    },
  },

  tasks: {
    getAll() {
      return ApiBase.get(ApiUrls.tasks.getAll);
    }
  },

  settings: {
    getAll() {
      return ApiBase.get(ApiUrls.settings.getAll);
    },

    getAdmin() {
      return ApiBase.get(ApiUrls.settings.getAdmin);
    },

    updateArchive(body) {
      return ApiBase.put(ApiUrls.settings.updateArchive, body);
    },

    updateStorage(body) {
      return ApiBase.put(ApiUrls.settings.updateStorage, body);
    },

    updateTimezone(body) {
      return ApiBase.put(ApiUrls.settings.updateTimezone, body);
    },

    updateAccess(body) {
      return ApiBase.put(ApiUrls.settings.updateAccess, body);
    }
  },

  journal: {
    getEvents(event, object, startDate, endDate) {
      return ApiBase.get(`${ApiUrls.journal.base}?event=${event}&object=${object}&startDate=${startDate}&endDate=${endDate}`);
    }
  }

}