<template>
  <v-container>

    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Документация</h2>
      </v-col>
    </v-row>

    <v-row class="mt-10">
      <v-col>
        <v-btn v-for="(item, index) in items" :key="index"
               color="primary"
               style="justify-content: start;"
               block text
               @click="onItemClicked(index)">
          <v-icon left>
            mdi-circle-medium
          </v-icon>
          {{ item }}
        </v-btn>
      </v-col>
    </v-row>

    <custom-dialog v-model="inDialog">
      <v-row>
        <vue-markdown class="mark"
                      :source="docSrc"
                      toc
                      toc-anchor-link-symbol="" />
      </v-row>
    </custom-dialog>

  </v-container>
</template>

<script>
import Dialog from "@/components/Dialog.vue"
import VueMarkdown from '@adapttive/vue-markdown'

export default {
  data() {
    return {
      item: null,
      items: [
        "Спецификация REST API",
        "Инструкция разработчика",
        "Инструкция администратора",
        "Инструкция аудитора"
      ],
      inDialog: false,

      swaggerUrl: process.env.VUE_APP_BACKEND_SWAGGER,
      docSrc: null
    }
  },

  methods: {
    onItemClicked(index) {
      switch(index) {
        case 0:
            window.open(this.swaggerUrl, "_blank");
          break;
        case 1:
            this.docSrc = require("!!raw-loader!@/assets/docs/developer.md").default;
            this.inDialog = true;
          break;
        case 2:
            this.docSrc = require("!!raw-loader!@/assets/docs/administrator.md").default;
            this.inDialog = true;
          break;
        case 3:
            this.docSrc = require("!!raw-loader!@/assets/docs/auditor.md").default;
            this.inDialog = true;
          break;
      }
    }
  },

  components: {
    VueMarkdown,
    CustomDialog: Dialog,
  }
};
</script>
