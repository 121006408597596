export default class TagObject {

  name = null;
  container = null;

  constructor(container = null, name = null) {
    this.name = name;
    this.container = container;
  }

  toRequest() {
    return {
      Container: this.container,
      Name: this.name
    }
  }

  toDummyResponse() {
    return {
      container: this.container,
      name: this.name
    }
  }
}