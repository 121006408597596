export default class TimezoneSettingObject {

  offset = 0;

  constructor(timezone) {
    if (timezone) {
      this.offset = timezone.offset;
    }
  }

  toRequest() {
    return {
      Offset: this.offset
    }
  }
}