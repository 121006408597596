<template>
  <VueDatePicker v-model="date"
                 format="DD.MM.YYYY"
                 rangeInputText="%d - %d"
                 rangeHeaderText="С %d по %d"
                 range
                 noHeader
                 placeholder="Укажите интервал"
                 :allowed-dates="allowedDates"
                 color="#01425e"
                 @onChange="onChange"
                 @onOpen="onOpen"
                 @onClose="onClose">

    <template #input-icon>
      <v-icon :color="iconColor">
        mdi-calendar-multiple
      </v-icon>
    </template>

  </VueDatePicker>
</template>

<script>
import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

export default {
  model: {
    prop: "value",
    event: "change"
  },

  props: {
    value: {
      require: true
    }
  },

  data() {
    let today = new Date();
    today.setHours(0, 0, 0, 0)

    return {
      date: this.value,

      today: today,
      iconColor: ""
    }
  },

  methods: {
    onChange() {
      this.$emit("change", this.date);
    },
    onOpen() {
      this.iconColor = "#01425e";
    },
    onClose() {
      this.iconColor = "";
    },

    allowedDates(date) {
      return new Date(date) >= this.today;
    }
  },

  watch: {
    "value": function(value) {
      this.date = value;
    }
  },

  components: {
    VueDatePicker
  }
}
</script>