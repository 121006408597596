<template>
  <v-container>
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Контейнеры</h2>
      </v-col>
      <v-col style="text-align: end">
        <v-btn color="primary" v-if="isAdmin" @click="openCreateModal">
          Создать
        </v-btn>
      </v-col>
    </v-row>

    <state-container ref="main">
      <v-container class="full-container">
        <search-panel
          class="mt-10"
          placeholder="Поиск по названию и ID"
          @search="onSearch"
        />

        <v-row>
          <v-col>
            <custom-table
              :items="list"
              :headers="headers"
              :search="searchQuery"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.size="{ item }">
                {{ item.size | bytes }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="isAdmin" @click="onEditClicked(item)">
                  mdi-pencil
                </v-icon>
              </template>
            </custom-table>
          </v-col>
        </v-row>
      </v-container>
    </state-container>

    <overlay :value="loading" />
    <custom-snackbar ref="snackbar" />

    <custom-dialog v-model="inDialog">
      <v-row class="mb-10" v-if="loadingEdit">
        <overlay :value="loadingEdit" :opacity="0"/>
      </v-row>
      <div v-else>
        <v-row>
          <v-col style="text-align: start">
            <h3>Контейнер</h3>
          </v-col>
        </v-row>

        <v-row class="mt-4" v-show="isEdit">
          <v-col>
            <v-text-field
              v-model="container.id"
              label="ID"
              outlined
              dense
              readonly
              hide-details
            />
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-form @submit.prevent ref="form" lazy-validation>
              <v-text-field
                v-model="container.name"
                placeholder="Только буквы латинского алфавита в нижнем регистре, цифры и дефис"
                label="Название"
                outlined
                dense
                :readonly="isEdit"
                required
                :rules="validationRules"
              />
            </v-form>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col style="text-align: start">
            <h3>Ключи доступа</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end">
            <v-btn
              color="primary"
              :loading="isKeyGenerating"
              :disabled="
                isKeyGenerating || isContainerSaving || isContainerDeleting
              "
              @click="onCreateKeyClicked"
            >
              Создать ключ доступа
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <custom-table
              :items="container.keys"
              :items-per-page="10"
              :headers="editHeaders"
            >
              <!-- eslint-disable-next-line -->
              <template v-slot:item.secret="{ item }">
                {{
                  item.secret && item.showed
                    ? item.secret
                    : "*****************************************"
                }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon v-if="item.secret" @click="item.showed = !item.showed">
                  {{ item.showed ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
                <v-icon @click="onDeleteKeyClicked(item)"> mdi-delete </v-icon>
              </template>
            </custom-table>
          </v-col>
        </v-row>

        <v-row class="mt-5 mb-1">
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end">
            <v-btn
              color="error"
              :disabled="
                !isEdit ||
                isContainerDeleting ||
                isKeyGenerating ||
                isContainerSaving
              "
              :loading="isContainerDeleting"
              @click="onDeleteContainerClicked"
            >
              Удалить контейнер
            </v-btn>
          </v-col>
          <v-col style="display: flex; justify-content: start">
            <v-btn
              color="primary"
              :loading="isContainerSaving"
              :disabled="
                isContainerSaving || isKeyGenerating || isContainerDeleting
              "
              @click="onSaveContainerClicked"
            >
              {{ isEdit ? "Сохранить изменения" : "Создать" }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div> 
    </custom-dialog>
  </v-container>
</template>

<script>
import ApiService from "@/services/api";
import DialogService from "@/services/dialog";

import Filters from "@/helpers/ui/filters";
import Rules from "@/helpers/validators/containers";

import Overlay from "@/components/Overlay.vue";
import StateContainer from "@/components/StateContainer.vue";
import Table from "@/components/Table.vue";
import Snackbar from "@/components/SnackBar.vue";
import Dialog from "@/components/Dialog.vue";
import SearchPanel from "@/components/SearchPanel.vue";

import StateMixins from "@/mixins/state";
import ErrorsMixins from "@/mixins/errors";

import ContainerObject from "@/store/objects/containers/ContainerObject";
import KeyObject from "@/store/objects/containers/KeyObject";

export default {
  mixins: [StateMixins, ErrorsMixins],

  computed: {
    isAdmin() {
      return this.$store.getters.role == "Admin";
    },
  },

  data() {
    return {
      loading: true,
      loadingEdit: false,
      searchQuery: null,
      headers: [
        { text: "Название", value: "name" },
        { text: "Объем", value: "size", filterable: false },
        { text: "Файлов", value: "files", filterable: false },
        { text: "", value: "actions", sortable: false, align: "end" },
        { value: "id", align: " d-none" },
      ],
      list: [],

      inDialog: false,
      isEdit: false,
      editHeaders: [
        { text: "Ключ", value: "key" },
        { text: "Секрет", value: "secret" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      container: new ContainerObject(),
      isKeyGenerating: false,
      isContainerSaving: false,
      isContainerDeleting: false,

      validationRules: Rules,
    };
  },

  mounted() {
    this.loadData();
  },

  methods: {
    loadData() {
      ApiService.containers
        .getAll()
        .then((response) => {
          this.list = response.data;
          if (!response.data.length) this.setNoDataState("main");
          this.loading = false;
        })
        .catch((error) => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        });
    },

    onSearch(search) {
      this.searchQuery = search;
    },

    openCreateModal() {
      this.container = new ContainerObject();
      this.inDialog = true;
      this.isEdit = false;

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    onEditClicked(item) {
      this.inDialog = true;
      this.loadingEdit = true;
      ApiService.containers
        .get(item.name)
        .then((response) => {
          this.container = new ContainerObject(response.data);
          this.isEdit = true;
          this.loadingEdit = false;
        })
        .catch((error) => {
          if (error.response) this.showError(error.response.data.error);
          else this.showError(error);
          this.loadingEdit = false;
        });
    },

    onCreateKeyClicked() {
      this.isKeyGenerating = true;
      ApiService.containers
        .createKey()
        .then((response) => {
          this.container.keys.push(
            new KeyObject(response.data.key, response.data.secret)
          );
          this.isKeyGenerating = false;
        })
        .catch((error) => {
          this.isKeyGenerating = false;
          this.showError(error.response.data.error);
        });
    },

    onDeleteKeyClicked(item) {
      this.container.keys = this.container.keys.filter((k) => k != item);
    },

    onSaveContainerClicked() {
      if (!this.$refs.form.validate()) return;

      this.isContainerSaving = true;
      var body = this.container.toRequest();
      if (this.isEdit) {
        ApiService.containers
          .update(body)
          .then(() => {
            this.inDialog = false;
            this.isContainerSaving = false;

            let dummyResponse = this.container.toDummyResponse();
            var foundIndex = this.list.findIndex(
              (c) => c.id == this.container.id
            );
            this.$set(this.list, foundIndex, dummyResponse);
          })
          .catch((error) => {
            this.isContainerSaving = false;
            this.showError(error.response.data.error);
          });
      } else {
        ApiService.containers
          .create(body)
          .then((response) => {
            this.inDialog = false;
            this.isContainerSaving = false;

            this.list.unshift(response.data);
          })
          .catch((error) => {
            this.isContainerSaving = false;
            this.showError(error.response.data.error);
          });
      }
    },

    onDeleteContainerClicked() {
      DialogService.showConfirm("Действительно удалить контейнер?").then(() => {
        this.isContainerDeleting = true;
        ApiService.containers
          .delete(this.container.name)
          .then(() => {
            this.inDialog = false;
            this.isContainerDeleting = false;

            var foundIndex = this.list.findIndex(
              (c) => c.id == this.container.id
            );
            this.$delete(this.list, foundIndex);
          })
          .catch((error) => {
            this.isContainerDeleting = false;
            this.showError(error.response.data.error);
          });
      });
    },

    onDialogClosed() {
      if (!this.inDialog) this.$refs.form.resetValidation();
    },
  },

  filters: {
    bytes: Filters.bytes,
  },

  watch: {
    inDialog: "onDialogClosed",
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table,
    CustomSnackbar: Snackbar,
    CustomDialog: Dialog,
    SearchPanel,
  },
};
</script>
