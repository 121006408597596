const systemNameRule = function(text) {
  if (!text || text.length == 0 || text.match(/^ *$/) != null)
    return "Системное имя файла не должно быть пустым";
  
  return true;
}

const delegationTypeRule = function(text) {
  return Boolean(text) || "Необходимо указать тип доступа";
}

const delegationPinRule = function(text) {
  return Boolean(text) || "Необходимо задать пин-код";
}

const delegationUserRule = function(text) {
  return Boolean(text) || "Необходимо выбрать пользователя";
}

export default {
  systemNameRules: [systemNameRule],
  delegationRules: {
    typeRules: [delegationTypeRule],
    pinRules: [delegationPinRule],
    userRules: [delegationUserRule]
  }
}