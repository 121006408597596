import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";

import "./assets/styles.css";
import "./assets/styles/variables.css";
import "./assets/styles/basic.css";

import vuetify from "@/plugins/vuetify";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VModal from "vue-js-modal";
Vue.use(VModal, { adaptive: true });

Vue.config.productionTip = false;

let vue = new Vue({
  router,
  store,
  render: (h) => h(App),
  vuetify,
}).$mount("#app");

export default vue;
