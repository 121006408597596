import AccessSettingObject from "./AccessSettingObject";
import ArchiveSettingObject from "./ArchiveSettingObject";
import StorageSettingObject from "./StorageSettingObject";
import TimezoneSettingObject from "./TimezoneSettingObject";

export default class SettingsObject {

  archive = [];
  access = new AccessSettingObject();
  storage = new StorageSettingObject();
  timezone = new TimezoneSettingObject();

  constructor(settings) {
    if (settings) {
      this.archive = settings.archive.map(a => new ArchiveSettingObject(a));
      this.access = new AccessSettingObject(settings.access);
      this.storage = new StorageSettingObject(settings.storage);
      this.timezone = new TimezoneSettingObject(settings.timezone);
    }
  }
}