<template>
  <v-app class="modal-wrapper">
    <v-card class="modal-content">
      <v-card-title class="title">
        {{ title }}
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        {{ text }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="buttons pt-3 pb-3">
        <v-spacer></v-spacer>
        <v-btn color="error"
               @click="onCancelClicked">
          Отмена
        </v-btn>
        <v-btn color="primary"
               @click="onOkClicked">
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import "@/assets/styles/modals.css"

export default {
  name: "ConfirmModalView",
  props: {
    title: {
      type: String,
      default: ""
    },
    text: {
      type: String,
      default: ""
    },
    okHandler: {
      type: Function,
      default: null
    },
    cancelHandler: {
      type: Function,
      default: null
    }
  },

  methods: {
    onOkClicked() {
      this.okHandler();
    },
    onCancelClicked() {
      this.cancelHandler();
    }
  }
};
</script>
