let mixin = {
  methods: {
    setBaseState(name) {
      this.$refs[name].setBaseState();
    },

    setNoDataState(name) {
      this.$refs[name].setNoDataState();
    },

    setErrorState(name, textError) {
      this.$refs[name].setErrorState(textError);
    },

    setEmptyState(name) {
      this.$refs[name].setEmptyState();
    }
  }
};

export default mixin;