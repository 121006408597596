import Vue from "../main"

import ConfirmModalView from "../components/modals/ConfirmModalView.vue"

export default {

  showConfirm(text) {
    return new Promise((resolve, reject) => {
      Vue.$modal.show(
        ConfirmModalView,
        {
          title: "Подтвердите действие",
          text: text,
          okHandler: () => {
            Vue.$modal.hide("ConfirmModalView", { closedByButton: true });
            resolve();
          },
          cancelHandler: () => {
            Vue.$modal.hide("ConfirmModalView", { closedByButton: true });
            reject();
          }
        },
        { name: "ConfirmModalView", height: "auto" },
        {
          "before-close": (event) => {
            if (!event.params || !event.params.closedByButton)
              reject();
          }
        }
      )
    });
  }

}