import {
  SET_DATA,
  CLEAR_DATA,
  SET_INIT,
  SET_DATA_TOKEN,
  SET_DATA_ACCOUNT,
  SET_DATA_ACCOUNT_VUEX,
  SET_ERROR,
  SET_REFRESH_TOKEN_TIMER,
  SET_REFRESH_TOKEN,
} from "../mutations";
import {
  CLEAR_DATA_ACTION,
  INIT_TOKEN,
  INIT_ACCOUNT,
  INIT_ACCOUNT_VUEX,
} from "../actions";
import EventBus from "@/main";
import AuthServiceADFS from "@/services/authADFS";

const state = {
  isInitialized: false,
  isAuthorized: false,
  isError: false,
  username: null,
  email: null,
  role: null,
  dataToken: {
    access_token: null,
    expires_in: null,
    id_token: null,
    scope: null,
    token_type: null,
  },
  dataAccount: null,
  timer: null,
};

const getters = {
  isInitialized: (state) => state.isInitialized,
  isAuthorized: (state) => state.isAuthorized,
  isError: (state) => state.isError,
  username: (state) => state.username,
  email: (state) => state.email,
  role: (state) => state.role,
  dataToken: (state) => state.dataToken,
  dataAccount: (state) => state.dataAccount,
  timer: (state) => state.timer,
};

const actions = {
  [CLEAR_DATA_ACTION](context) {
    context.commit(CLEAR_DATA);
  },

  [INIT_TOKEN]: async (context) => {
    if (!context.getters.dataToken.access_token) {
      var dataToken = await AuthServiceADFS.getToken();
    }
    if (dataToken) {
      let timer = new Date(Date.now() + dataToken.expires_in * 1000).toString();

      context.commit(SET_DATA_TOKEN, {
        access_token: dataToken.access_token,
        expires_in: dataToken.expires_in,
        id_token: dataToken.id_token,
        scope: dataToken.scope,
        token_type: dataToken.token_type,
      });

      context.commit(SET_REFRESH_TOKEN_TIMER, timer);
    }
  },

  [INIT_ACCOUNT]: async (context) => {
    let dataAccount = await AuthServiceADFS.getAccount();

    if (dataAccount) {
      context.commit(SET_DATA_ACCOUNT, dataAccount.data);
      context.commit(SET_DATA, {
        isAuthorized: true,
        username: dataAccount.data.name,
        email: dataAccount.data.email,
        role: dataAccount.data.role,
      });
      context.commit(SET_INIT);
    }
  },

  [INIT_ACCOUNT_VUEX]: async (context) => {
    var dataAccountVuex = JSON.parse(localStorage.getItem("vuex")).store
      .AccountModule;
    let stateAccountVuex = {
      isInitialized: dataAccountVuex.isInitialized,
      isAuthorized: dataAccountVuex.isAuthorized,
      isError: dataAccountVuex.isError,
      username: dataAccountVuex.username,
      email: dataAccountVuex.email,
      role: dataAccountVuex.role,
      dataToken: dataAccountVuex.dataToken,
      dataAccount: dataAccountVuex.dataAccount,
      timer: dataAccountVuex.timer,
    };
    context.commit(SET_DATA_ACCOUNT_VUEX, stateAccountVuex);
  },
};

const mutations = {
  [SET_DATA](state, { isAuthorized, username, email, role }) {
    state.isAuthorized = isAuthorized;
    state.username = username;
    state.email = email;
    state.role = role;

    if (EventBus) EventBus.$emit("LOGGED");
  },

  [SET_DATA_ACCOUNT_VUEX](state, vuexData) {
    state.isInitialized = vuexData.isInitialized;
    state.isAuthorized = vuexData.isAuthorized;
    state.isError = vuexData.isError;
    state.username = vuexData.username;
    state.email = vuexData.email;
    state.role = vuexData.role;
    state.dataToken = vuexData.dataToken;
    state.dataAccount = vuexData.dataAccount;
    state.timer = vuexData.timer;
  },

  [CLEAR_DATA](state) {
    state.isInitialized = false;
    state.isAuthorized = false;
    state.isError = false;
    state.username = null;
    state.email = null;
    state.role = null;
    state.dataToken = {
      access_token: null,
      expires_in: null,
      id_token: null,
      scope: null,
      token_type: null,
    };
    state.dataAccount = null;
    state.timer = null;
  },
  [SET_INIT](state) {
    state.isInitialized = true;
  },
  [SET_DATA_TOKEN](
    state,
    { access_token, expires_in, id_token, scope, token_type }
  ) {
    state.dataToken.access_token = access_token;
    state.dataToken.expires_in = expires_in;
    state.dataToken.id_token = id_token;
    state.dataToken.scope = scope;
    state.dataToken.token_type = token_type;
  },
  [SET_DATA_ACCOUNT](state, dataAccount) {
    state.dataAccount = dataAccount;
  },
  [SET_ERROR](state, isError) {
    state.isError = isError;
  },
  [SET_REFRESH_TOKEN](
    state,
    { access_token, expires_in, id_token, scope, token_type }
  ) {
    state.dataToken.access_token = access_token;
    state.dataToken.expires_in = expires_in;
    state.dataToken.id_token = id_token;
    state.dataToken.scope = scope;
    state.dataToken.token_type = token_type;
  },
  [SET_REFRESH_TOKEN_TIMER](state, timer) {
    state.timer = timer;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
