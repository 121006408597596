const filter = function (value, search) {
  let regExp = new RegExp(search.toLocaleLowerCase(), "g");

  return (
    value != null &&
    search != null &&
    typeof value !== "boolean" &&
    regExp.test(value.toString().toLocaleLowerCase()) != 0
  );
};

export default {
  customFilter(value, search, item) {
    if (value instanceof Array)
      return value.some((v) => filter(v, search));
    else
      return filter(value, search);
  },
};
