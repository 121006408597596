<template>
  <v-container>
    
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Пользователи</h2>
      </v-col>
      <v-col style="text-align: end">
        <v-btn style="margin: 10px;" color="primary"
               @click="onAddClicked"
               v-show="container && isAdmin"
               :disabled="isMetaLoading"
               :loading="isMetaLoading">
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <state-container ref="main">
      <v-container class="full-container">
        <v-row class="mt-10">
          <v-col class="container-selector">
            <h4 class="mt-2 mr-5">Контейнер</h4>
            <v-select :items="containersList"
                      v-model="container"
                      label="Выберите контейнер"
                      solo
                      dense
                      @change="onContainerSelected">
            </v-select>
          </v-col>
        </v-row>

        <state-container ref="list">
          <v-container class="full-container">
            
            <search-panel @search="onSearch" />

            <v-row>
              <v-col>
                <custom-table :items="list"
                              :headers="computedHeaders"
                              :search="searchQuery">

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.role="{ item }">
                    {{ item.role | roles }}
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.tags="{ item }">
                    {{ item.tags.length }}
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.containers="{ item }">
                    <span style="white-space: break-spaces;">{{ item.containers | containers }}</span>
                  </template>

                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if="isAdmin"
                            @click="onEditClicked(item, false)">
                      mdi-pencil
                    </v-icon>
                  </template>

                </custom-table>
              </v-col>
            </v-row>
          </v-container>
        </state-container>
    </v-container>
    </state-container>

    <overlay :value="loading" />
    <custom-snackbar ref="snackbar" />

    <custom-dialog v-model="inDialog">
      <v-row class="mb-10" v-if="loadingEdit">
        <overlay :value="loadingEdit" :opacity="0"/>
      </v-row>
      <div v-else>
        <v-row>
          <v-col style="text-align: start">
            <h3>Пользователь</h3>
          </v-col>
        </v-row>

        <v-form @submit.prevent ref="form" lazy-validation>
    
          <v-row class="mt-4">
            <v-col class="mb-n6">
              <v-text-field v-if="!isEdit"
                            v-model="user.email"
                            :loading="isAdLoading"
                            placeholder="Введите email"
                            label="Email"
                            :rules="validationRules.userRules.emailRules"
                            outlined
                            dense />
              <v-text-field v-if="isEdit"
                            v-model="user.email"
                            label="Email"
                            outlined
                            dense
                            readonly />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select v-model="user.role"
                        :items="roles"
                        item-text="title"
                        item-value="value"
                        label="Роль"
                        outlined
                        dense
                        required
                        :rules="validationRules.userRules.roleRules"
                        class="mb-n6" />
            </v-col>
          </v-row>

        </v-form>

        <v-row v-if="!isCreatingInContainer"
              class="mt-5">
          <v-col style="text-align: start">
            <h3 style="display: inline">Контейнеры, к которым предоставлен доступ</h3>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-3"
                        dense
                        v-bind="attrs"
                        v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>При удалении контейнера будут удалены все теги и заместители, связанные с этим контейнером</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row v-if="!isCreatingInContainer"
              class="mt-4">
          <v-col>
            <v-autocomplete v-if="!isCreatingInContainer"
                            v-model="user.containers"
                            :items="containers"
                            placeholder="Введите название контейнера для поиска"
                            label="Контейнеры"
                            outlined
                            dense
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            :rules="validationRules.userRules.containerRules"
                            required
                            @input="onUserContainersChanged"
                            class="mb-n6">
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mt-5">
          <v-col style="text-align: start">
            <h3 style="display: inline">Основные теги, к которым предоставлен доступ</h3>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-3"
                        dense
                        v-bind="attrs"
                        v-on="on">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>При удалении тега будут удалены все заместители, связанные с этим тегом</span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-col>
            <v-autocomplete v-model="user.tags"
                            :items="tagsForUserForSelect"
                            :item-text="tagConverter"
                            placeholder="Введите название контейнера или тега для поиска"
                            label="Теги"
                            outlined
                            dense
                            multiple
                            chips
                            small-chips
                            deletable-chips
                            return-object
                            :no-data-text="emptyTagsText"
                            @input="onUserTagsChanged"
                            class="mb-n6">
            </v-autocomplete>
          </v-col>
        </v-row>
    
        <v-row class="mt-5">
          <v-col style="text-align: start">
            <h3>Заместители</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end;">
            <v-btn color="primary"
                  :disabled="!user.tags.length || isUserSaving || isUserDeleting"
                  @click="onAddDelegationClicked">
              Добавить заместителя
            </v-btn>
          </v-col>
        </v-row>
    
        <v-row>
          <v-col>
            <custom-table :items="user.delegations"
                          :items-per-page="10"
                          :headers="editHeaders">
    
              <!-- eslint-disable-next-line -->
              <template v-slot:item.name="{ item }">
                <div>{{ item.name }}</div>
                <div>{{ item.email }}</div>
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.tag="{ item }">
                {{ item.tag | tag }}
              </template>

              <!-- eslint-disable-next-line -->
              <template v-slot:item.date="{ item }">
                <div>C {{ item.startsOn | date }}</div>
                <div>по {{ item.expireOn | date }}</div>
              </template>
    
              <!-- eslint-disable-next-line -->
              <template v-slot:item.actions="{ item }">
                <v-icon @click="onEditDelegationClicked(item)">
                  mdi-pencil
                </v-icon>
                <v-icon @click="onDeleteDelegationClicked(item)">
                  mdi-delete
                </v-icon>
              </template>
    
            </custom-table>
          </v-col>
        </v-row>
    
        <v-row class="mt-5 mb-1">
          <v-spacer></v-spacer>
          <v-col style="display: flex; justify-content: end;">
            <v-btn color="error"
                  :disabled="!isEdit || isUserSaving || isUserDeleting"
                  :loading="isUserDeleting"
                  @click="onDeleteUserClicked">
              Удалить пользователя
            </v-btn>
          </v-col>
          <v-col style="display: flex; justify-content: start;">
            <v-btn color="primary"
                  @click="onSaveUserClicked"
                  :disabled="isUserSaving || isUserDeleting"
                  :loading="isUserSaving">
              {{ isEdit ? "Сохранить изменения" : "Добавить" }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
      </div>
      
    </custom-dialog>

    <custom-dialog v-model="inDelegationsDialog">
      <v-form @submit.prevent ref="delegationForm" lazy-validation>
        <v-row>
          <v-col>
            <v-autocomplete v-model="delegation.tag"
                            :items="user.tags"
                            :item-text="tagConverter"
                            placeholder="Введите название контейнера или тега для поиска"
                            label="Тег"
                            outlined
                            dense
                            return-object
                            :readonly="isDelegationEdit"
                            @change="onTagForDelegationChanged"
                            required
                            :rules="validationRules.delegationRules.tagRules"
                            class="mb-n6" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete v-model="delegation.user"
                            :items="usersForDelegation"
                            item-text="email"
                            placeholder="Введите email для поиска"
                            label="Email"
                            :no-data-text="delegationUserNoDataText"
                            outlined
                            dense
                            return-object
                            :readonly="isDelegationEdit"
                            :loading="isUsersForDelegationLoading"
                            @change="onDelegationEmailChanged"
                            required
                            :rules="validationRules.delegationRules.userRules"
                            class="mb-n2" />
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col class="date-selector">
          <span class="mr-5">Интервал предоставления доступа</span>
          <custom-date-picker v-model="accessDates" />
        </v-col>
      </v-row>

      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 @click="inDelegationsDialog = false">
            Отменить
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 @click="onSaveDelegationClicked"
                 :loading="isUsersForDelegationLoading"
                 :disabled="isUsersForDelegationLoading">
            Добавить
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

  </v-container>
</template>

<script>
import "@/assets/styles/pages.css"

import ApiService from "@/services/api"
import DialogService from "@/services/dialog"

import UserObject from "@/store/objects/users/UserObject"
import DelegationObject from "@/store/objects/users/DelegationObject"
import TagObject from "@/store/objects/users/TagObject"

import Overlay from "@/components/Overlay.vue"
import StateContainer from "@/components/StateContainer.vue"
import Table from "@/components/Table.vue"
import Snackbar from "@/components/SnackBar.vue"
import Dialog from "@/components/Dialog.vue"
import DatePicker from "@/components/DatePicker.vue"
import SearchPanel from "@/components/SearchPanel.vue"

import Filters from "@/helpers/ui/filters"
import Rules from "@/helpers/validators/users"

import StateMixins from "@/mixins/state"
import ErrorsMixins from "@/mixins/errors"

import BlConsts from "@/store/consts/bl"

import EventBus from "@/main"

const ALL_CONTAINERS = "Все контейнеры"

export default {
  mixins: [StateMixins, ErrorsMixins],

  props: {
    currentUser: Boolean
  },

  computed: {
    isAdmin() {
      return this.$store.getters.role == "Admin";
    },

    computedHeaders() {
      if (this.container == ALL_CONTAINERS) {
        let temp = [...this.headers];
        temp.splice(4, 0, { text: "Контейнеры", value: "containers" });
        return temp;
      }
      else {
        return this.headers;
      }
    },

    tagsForUserForSelect() {
      if (!this.user || !this.tagsMetaList) {
        return []
      }
      else {
        return this.tagsMetaList.filter(t => this.user.containers.some(c => c == t.container));
      }
    },

    emptyTagsText() {
      if (this.user && this.isCreatingInContainer)
        return "В контейнере нет основных тегов";
      else if (this.user && this.user.containers.length)
        return "В выбранных контейнерах нет основных тегов";
      else
        return "Выберите контейнер, чтобы увидеть теги";
    },

    delegationUserNoDataText() {
      if (this.delegation.tag)
        return "Нет пользователей, имеющих доступ к выбранному контейнеру";
      else
        return "Выберите тег, чтобы увидеть список пользователей";
    }
  },

  data() {
    return {
      loading: true,
      loadingEdit: true,
      searchQuery: null,
      headers: [
        { text: "ФИО", value: "name" },
        { text: "Email", value: "email" },
        { text: "Роль", value: "role" },
        { text: "Тегов", value: "tags" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ],

      containersList: [],
      containers: [],
      container: null,

      list: [],
      isAdLoading: false,
      adSearchQuery: null,
      adList: [],
      tagsMetaList: null,
      usersForDelegation: null,

      roles: BlConsts.roles,

      inDialog: false,
      inDelegationsDialog: false,
      isEdit: false,
      isCreatingInContainer: false,
      isDelegationEdit: false,
      user: new UserObject(),
      delegation: new DelegationObject(),
      accessDates: {},
      isMetaLoading: false,
      editHeaders: [
        { text: "Пользователь", value: "name" },
        { text: "Основной тег", value: "tag" },
        { text: "Период", value: "date" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ],
      isUserSaving: false,
      isUserDeleting: false,
      isUsersForDelegationLoading: false,
      isUserCreating: false,

      validationRules: Rules,

      currentUserObject: null,
      isCurrentUserEdit: false,

      bounceTimer: null
    }
  },

  mounted() {
    EventBus.$on("USER_CLICKED", this.onCurrentUserClicked);
    this.setEmptyState("list");
    this.loadContainerList();
  },

  beforeDestroy() {
    EventBus.$off("USER_CLICKED");
  },

  methods: {
    getDatesOffset() {
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + this.$store.getters.days - 1);
      return { start: startDate, end: endDate };
    },

    loadContainerList() {
      ApiService.containers.getNames()
        .then(response => {
          this.containersList = response.data;
          if (!response.data.length) {
            this.setNoDataState("main");
          }
          else {
            this.containersList = [ALL_CONTAINERS, ...response.data];
            this.containers = response.data;
            if (this.currentUser)
              this.openCurrentUser();
          }

          if (!this.currentUser)
            this.loading = false;
        })
        .catch(error => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        });
    },

    onContainerSelected(container) {
      if (container == ALL_CONTAINERS)
        this.loadAllUsers();
      else
        this.loadContainerUsers(container);
    },

    loadAllUsers() {
      this.loading = true;
      ApiService.users.getAll()
        .then(response => {
          this.list = response.data;
          if (!response.data.length)
            this.setNoDataState("list");
          else
            this.setBaseState("list");
          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("list", error);
          this.loading = false;
          console.log(error);
        });
    },

    loadContainerUsers(container) {
      this.loading = true;

      ApiService.users.getForContainer(container)
        .then(response => {
          this.list = response.data;
          if (!response.data.length)
            this.setNoDataState("list");
          else
            this.setBaseState("list");
          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("list", error);
          this.loading = false;
          console.log(error);
        });
    },

    onSearch(search) {
      this.searchQuery = search;
    },

    loadMeta(edit, item) {
      if (!this.tagsMetaList) {
        this.isMetaLoading = true;
        ApiService.tags.getMainTagsNames()
          .then(response => {
            this.tagsMetaList = response.data.map(t => new TagObject(t.container, t.name));
            if (edit)
              this.onEditClicked(item);
            else if (!edit)
              this.onAddClicked();
          })
          .catch(error => {
            if (error.response)
              this.showError(error.response.data.error);
            else
              this.showError(error);
            this.isMetaLoading = false;
          });
      }
    },

    onAddClicked() {
      if (!this.tagsMetaList) {
        this.loadMeta(false);
      } else {
        this.loading = false;
        this.loadingEdit = false;
        let isAllContainers = false;
        this.isMetaLoading = false;
        this.isUserCreating = false;
        this.isCreatingInContainer = false;
        this.isCurrentUserEdit = false;
        this.isEdit = false;

        if (this.container && this.container == ALL_CONTAINERS) {
          isAllContainers = true;
        }
        this.openAddModal(isAllContainers);
      }
    },

    onEditClicked(item, current) {
      this.inDialog = true;
      this.isCurrentUserEdit = current;
      if (this.tagsMetaList) {
        this.loading = false;
        this.loadingEdit = false;
        this.isMetaLoading = false;
        this.isUserCreating = false;
        this.isCreatingInContainer = false;
        this.isCurrentUserEdit = false;
        this.isEdit = true;

        this.openEditModal(item);
      } else {
        this.loadMeta(true, item);
      }
    },

    openAddModal(isAllContainers) {
      this.inDialog = true;
      this.user = new UserObject();

      if (!isAllContainers) {
        this.isCreatingInContainer = true;
        this.user.containers = [this.container];
      } else {
        this.isUserCreating = true;
      }

      this.isMetaLoading = false;

      if (this.$refs.form)
          this.$refs.form.resetValidation();
    },

    openEditModal(item) {
      this.user = new UserObject(item);
      this.inDialog = true;
      this.isMetaLoading = false;

      if (this.$refs.form)
          this.$refs.form.resetValidation();
    },

    onUserContainersChanged() {
      this.user.tags = this.user.tags.filter(t => this.user.containers.some(c => c == t.container));
      this.onUserTagsChanged();
    },

    onUserTagsChanged() {
      this.user.delegations = this.user.delegations.filter(d => this.user.tags.some(t => t.container == d.tag.container && t.name == d.tag.name));
    },

    onAddDelegationClicked() {
      this.delegation = new DelegationObject();
      this.usersForDelegation = [];
      this.inDelegationsDialog = true;
      this.isDelegationEdit = false;
      this.accessDates = this.getDatesOffset();

      if (this.$refs.delegationForm)
        this.$refs.delegationForm.resetValidation();
    },

    onEditDelegationClicked(item) {
      this.delegation = new DelegationObject(item);
      this.usersForDelegation = [this.delegation.user];
      this.inDelegationsDialog = true;
      this.isDelegationEdit = true;

      this.accessDates = { start: this.delegation.startsOn, end: this.delegation.expireOn };
    },

    onSaveDelegationClicked() {
      if (!this.$refs.delegationForm.validate())
        return;

      this.delegation.startsOn = this.accessDates.start;
      this.delegation.expireOn = this.accessDates.end;

      if (this.isDelegationEdit) {
        let foundIndex = this.user.delegations.findIndex(d => d.email == this.delegation.email);
        this.$set(this.user.delegations, foundIndex, this.delegation);
      }
      else {
        this.user.delegations.unshift(this.delegation);
      }
      this.inDelegationsDialog = false;
    },

    onDeleteDelegationClicked(item) {
      var foundIndex = this.user.delegations.findIndex(d => d.email == item.email);
      this.$delete(this.user.delegations, foundIndex);
    },

    onTagForDelegationChanged(item) {
      this.isUsersForDelegationLoading = true;
      this.delegation.user = null;
      this.delegation.email = null;
      this.delegation.name = null;
      ApiService.users.getNamesForContainer(item.container)
        .then(response => {
          this.usersForDelegation = response.data.filter(u => u.email != this.user.email);
          this.isUsersForDelegationLoading = false;
        })
        .catch(error => {
          if (error.response)
            this.showError(error.response.data.error);
          else
            this.showError(error);
          this.isUsersForDelegationLoading = false;
        });
    },

    onDelegationEmailChanged(item) {
      this.delegation.email = item.email;
      this.delegation.name = item.name;
    },

    onSaveUserClicked() {
      if (!this.$refs.form.validate())
        return;

      this.isUserSaving = true;
      let body = this.user.toRequest();

      if (this.isUserCreating || this.isCreatingInContainer) {
          ApiService.users.create(body)
            .then(response => {
              this.inDialog = false;
              this.isUserSaving = false;
              this.isUserCreating = false;

              this.list.unshift(response.data);
              this.setBaseState("list");
            })
            .catch(error => {
              if (error.response.status == 422) {
                this.processUserRestore();
                return;
              }
              if (error.response)
                this.showError(error.response.data.error);
              else
                this.showError(error);
              
              this.isUserSaving = false;
              this.isUserSaving = false;
            });
        } else {
          ApiService.users.update(body)
            .then(() => {
              this.inDialog = false;
              this.isUserSaving = false;

              if (this.isCurrentUserEdit) {
                this.currentUserObject = null;
                return;
              }
              
              if (this.container != ALL_CONTAINERS && !this.user.containers.some(c => c == this.container)) {
                var foundIndex = this.list.findIndex(u => u.id == this.user.id);
                this.$delete(this.list, foundIndex);
              }
              else {
                let dummyResponse = this.user.toDummyResponse();
                let foundIndex = this.list.findIndex(t => t.id == dummyResponse.id);
                this.$set(this.list, foundIndex, dummyResponse);
              }
            })
            .catch(error => {
              this.isUserSaving = false;
              if (error.response)
              this.showError(error.response.data.error);
              else
                this.showError(error);
            });
      }
    },

    onDeleteUserClicked() {
      DialogService.showConfirm("Удалить пользователя?")
        .then(() => {
          this.isUserDeleting = true;
          ApiService.users.delete(this.user.email)
            .then(() => {
              this.inDialog = false;
              this.isUserDeleting = false;

              var foundIndex = this.list.findIndex(u => u.id == this.user.id);
              this.$delete(this.list, foundIndex);
            })
            .catch(error => {
              this.isUserDeleting = false;
              this.showError(error.response.data.error);
            });
        });
    },

    processUserRestore() {
      DialogService.showConfirm("Пользователь был удален. Восстановить пользователя?")
        .then(() => {
          ApiService.users.restore(this.user.email)
            .then(() => {
              this.inDialog = false;
              this.isUserSaving = false;
              this.container = ALL_CONTAINERS;
              this.onContainerSelected(ALL_CONTAINERS);
              this.showError("Пользователь восстановлен");
            })
            .catch(error => {
              this.isUserSaving = false;
              if (error.response)
                this.showError(error.response.data.error);
              else
                this.showError(error);
            });
        })
        .catch(() => this.isUserSaving = false);
    },

    onCurrentUserClicked() {
      this.openCurrentUser();
    },

    openCurrentUser() {
      this.loading = true;
      if (!this.currentUserObject) {
        ApiService.users.get(this.$store.getters.email)
        .then(response => {
          this.currentUserObject = response.data;
          this.onEditClicked(response.data, true);
        })
        .catch(error => {
          if (error.response)
            this.showError(error.response.data.error);
          else
            this.showError(error);

          this.loading = false;
        });
      }
      else {
        this.onEditClicked(this.currentUserObject, true);
        this.loading = false;
      }
    },

    loadUsers(container, notAdded) {
      this.isAdLoading = true;

      ApiService.users.getNamesForContainer(container, notAdded)
        .then(response => {
          this.adList = response.data;
          this.isAdLoading = false;
        })
        .catch(error => {
          if (error.response)
            this.showError(error.response.data.error);
          else
            this.showError(error);
          this.isAdLoading = false;
        });
    },

    tagConverter: function(tag) {
      return Filters.tag(tag);
    }
  },

  filters: {
    roles: Filters.roles,
    tag: Filters.tag,
    date: Filters.date,
    containers: Filters.containers,
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table,
    CustomSnackbar: Snackbar,
    CustomDialog: Dialog,
    CustomDatePicker: DatePicker,
    SearchPanel
  }
};
</script>
