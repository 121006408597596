const lengthRule = function(text) {
  if (!text || text.length < 3 || text.length > 63)
    return "Название контейнера должно быть в диапазоне от 3 до 63 символов";
  
  return true;
}

const regExpRule = function(text) {
  return text && text.match(/^([a-z0-9]+([-][a-z0-9]+)*[a-z0-9]*)$/) != null || "Название не соответствует поддерживаемому формату";
}

export default [lengthRule, regExpRule]