const tagRule = function(text) {
  return Boolean(text) || "Необходимо выбрать тег";
}

const daysRequiredRule = function(text) {
  return Boolean(text) || "Необходимо указать кол-во дней";
}

const daysRangeRule = function(text) {
  return text > 0 && text < 366 || "Значение должно быть в диапазоне от 1 до 365";
}

export default {
  tagRules: [tagRule],
  daysRules: [daysRequiredRule, daysRangeRule]
}