import store from "../store/index";
import {
  CLEAR_DATA_ACTION,
  INIT_ACCOUNT,
  INIT_ACCOUNT_VUEX,
  INIT_TOKEN,
  INIT_SETTINGS,
} from "@/store/actions";
import ApiService from "@/services/api";
import ApiServiceADFS from "@/services/apiADFS/apiADFS.js";
import NotificationService from "@/services/notification";

import { SET_ERROR } from "@/store/mutations";

const LOGIN_REDIRECT_URI = process.env.VUE_APP_LOGIN_REDIRECT_URI;
const LOGOUT_REDIRECT_URI = process.env.VUE_APP_LOGOUT_REDIRECT_URI;
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;

const initAccount = async function () {
  if (localStorage.getItem("vuex")) {
    store.dispatch(INIT_ACCOUNT_VUEX);
  } else {
    await store.dispatch(INIT_TOKEN);
    await store.dispatch(INIT_ACCOUNT);
  }

  if (
    store.getters.dataToken.access_token &&
    !store.getters.days &&
    !store.getters.offset
  ) {
    store.dispatch(INIT_SETTINGS);
  }
};

const errorMessageConnectADFS = function (error) {
  NotificationService.showNotification(
    "Ошибка подключения к ADFS",
    "Отправьте разработчику текст ошибки из консоли или Попробуйте позже",
    `Ошибка: ${error}`
  ).then(() => {
    clearData();
    document.location = LOGOUT_REDIRECT_URI;
  });
};

const clearData = async function () {
  store.dispatch(CLEAR_DATA_ACTION);
  window.localStorage.clear();
};

const login = function () {
  clearData();

  try {
    const NEWauthorityParams = {
      response_type: "id_token token",
      client_id: CLIENT_ID,
      response_mode: "fragment",
      redirect_uri: LOGIN_REDIRECT_URI,
      scope: "openid",
      nonce: "ffgv_ekdkkvm22_fkro",
    };

    let authoritySearchParams = new URLSearchParams(NEWauthorityParams);
    let authorityUrl = ApiServiceADFS.account.login(authoritySearchParams);

    document.location = authorityUrl;
  } catch (error) {
    console.error("ERROR_LOGIN", error);
    errorMessageConnectADFS(error);
  }
};

const logout = async function () {
  try {
    const logoutParams = {
      id_token_hint: store.getters.dataToken.id_token,
      post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
    };

    let logoutSearchParams = new URLSearchParams(logoutParams);
    let logoutUrl = ApiServiceADFS.account.logout(logoutSearchParams);

    clearData();
    document.location = logoutUrl;
  } catch (error) {
    console.error("ERROR_LOGOUT", error);
    errorMessageConnectADFS(error);
  }
};

const getToken = async function () {
  try {
    if (!store.getters.dataToken.access_token) {
      const urlHash = window.location.hash;
      const urlSearchParams = urlHash.replace("#", "?");

      const urlParams = new URLSearchParams(urlSearchParams);
      const dataTokenResponse = Object.fromEntries(urlParams.entries());

      return dataTokenResponse;
    }
  } catch (error) {
    console.error("ERROR_GET_DATA_TOKEN", error);

    NotificationService.showNotification(
      "Ошибка получения токена",
      "Отправьте разработчику текст ошибки из консоли или Попробуйте позже",
      `Текст ошибки: ${error.response.statusText}`,
      `Запрос: ${error.response.config.url}`,
      `Код ошибки: ${error.response.status}`
    ).then(() => {
      clearData();
      document.location = LOGOUT_REDIRECT_URI;
    });

    return null;
  }
};

const getAccount = async function () {
  if (
    store.getters.dataToken.access_token &&
    !store.getters.isInitialized &&
    !store.getters.dataAccount
  ) {
    let dataAccountResponse = await ApiService.users
      .getCurrentUser()
      .catch((error) => {
        if (!store.getters.isError) {
          console.error("ERROR_GET_USER_DATA", error);
          store.commit(SET_ERROR, true);

          NotificationService.showNotification(
            "Ошибка получения данных о пользователе из БД.",
            "Отправьте разработчику текст ошибки из консоли или Попробуйте позже",
            `Текст ошибки: ${error.response.statusText}`,
            `Запрос: ${error.response.config.url}`,
            `Код ошибки: ${error.response.status}`
          ).then(() => {
            logout();
          });

          return null;
        }
      });

    return dataAccountResponse;
  }

  return null;
};

export default {
  login,
  logout,
  getToken,
  getAccount,
  initAccount,
  clearData,
};
