import TagObject from "./TagObject";
import DelegationObject from "./DelegationObject";

export default class UserObject {

  id = null;
  name = null;
  email = null;
  sid = null;
  role = null;
  tags = [];
  delegations = [];
  containers = [];

  constructor(user = null) {
    if (user) {
      this.id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.sid = user.sid;
      this.role = user.role;
      this.tags = user.tags.map(t => new TagObject(t.container, t.name));
      this.delegations = user.delegations.map(d => new DelegationObject(d));
      this.containers = user.containers;
    }
  }

  toRequest() {
    return {
      Id: this.id,
      SID: this.sid,
      email: this.email,
      Role: this.role,
      Tags: this.tags.map(t => t.toRequest()),
      Delegations: this.delegations.map(d => d.toRequest()),
      Containers: this.containers
    }
  }

  toDummyResponse() {
    return {
      id: this.id,
      name: this.name,
      email: this.email,
      role: this.role,
      tags: this.tags.map(t => t.toDummyResponse()),
      delegations: this.delegations.map(d => d.toDummyResponse()),
      containers: this.containers
    }
  }
}