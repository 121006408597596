import Vue from "vue";
import Vuex from "vuex";

import AccountModule from "@/store/modules/account";
import SettingsModule from "@/store/modules/settings";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AccountModule,
    SettingsModule,
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer: (vuexState) => {
        return {
          store: {
            AccountModule: {
              isInitialized: vuexState.AccountModule.isInitialized,
              isAuthorized: vuexState.AccountModule.isAuthorized,
              isError: vuexState.AccountModule.isError,
              username: vuexState.AccountModule.username,
              email: vuexState.AccountModule.email,
              role: vuexState.AccountModule.role,
              dataToken: vuexState.AccountModule.dataToken,
              dataAccount: vuexState.AccountModule.dataAccount,
              timer: vuexState.AccountModule.timer,
            },
            SettingsModule: {
              access: vuexState.SettingsModule.days,
              timezone: vuexState.SettingsModule.offset,
            },
          },
        };
      },
    }),
  ],
});
