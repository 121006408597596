<template>
  <v-data-table :items="items"
                :headers="headers"
                :search="search"
                :items-per-page="itemsPerPage"
                :footer-props="{
                  showCurrentPage: true,
                  'items-per-page-text': 'Элементов на странице:',
                  'items-per-page-options': [10, 25, 50, 75, 100]
                }"
                :custom-filter="customFilter"
                :hide-default-footer="hideDefaultFooter"
                :fixed-header="fixedHeader"
                no-data-text="Нет данных"
                no-results-text="Ничего не найдено"
                @click:row="onRowClicked"
                @pagination="onPagination"
                class="custom-table">

    <!-- pass through scoped slots -->
    <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
      <slot :name="scopedSlotName" v-bind="slotData" />
    </template>

    <!-- pass through normal slots -->
    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:footer.page-text="data">
      {{ `${data.pageStart} - ${data.pageStop} из ${data.itemsLength}` }}
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:footer.prepend="data">
      <v-progress-linear indeterminate :active="loading"></v-progress-linear>
    </template>

  </v-data-table>
</template>

<script>
import "@/assets/styles/components.css"

import TableUtils from "@/helpers/utils/tableUtils"

export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      default: () => []
    },
    search: {
      type: String,
      default: undefined
    },
    itemsPerPage: {
      type: Number,
      default: 50
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false
    },
    fixedHeader: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      customFilter: TableUtils.customFilter
    }
  },

  methods: {
    onRowClicked(row) {
      this.$emit("click", row);
    },

    onPagination(param){
      this.$emit("pagination", param);
    }
  }
}
</script>