import { SET_SETTINGS, SET_OFFSET, SET_DAYS } from "../mutations";
import { INIT_SETTINGS } from "../actions";

import ApiService from "@/services/api";

const state = {
  offset: false,
  days: false,
};

const getters = {
  offset: (state) => state.offset,
  days: (state) => state.days,
};

const actions = {
  [INIT_SETTINGS](context) {
    ApiService.settings.getAdmin()
      .then((response) =>
        context.commit(SET_SETTINGS, {
          offset: response.data.timezone.offset,
          days: response.data.access.days,
        })
      )
      .catch(() => context.commit(SET_SETTINGS, { offset: 180, days: 0 }));
  },
};

const mutations = {
  [SET_SETTINGS](state, { offset, days }) {
    state.offset = offset;
    state.days = days;
  },
  [SET_OFFSET](state, offset) {
    state.offset = offset;
  },
  [SET_DAYS](state, days) {
    state.days = days;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
