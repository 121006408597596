export default class TagAccessObject {

  name = null;
  email = null;
  tag = null;

  constructor(access) {
    if (access) {
      this.email = access.email;
      this.name = access.name;
      this.tag = access.tag;
    }
  }

  toDummyResponse() {
    return {
      name: this.name,
      email: this.email,
      tag: this.tag
    }
  }
}