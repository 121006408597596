<template>
  <v-app id="app">
    <vue-extend-layouts />
  </v-app>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'

export default {
  name: 'App',
  components: { VueExtendLayouts }
}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #151b21;
  background: #f4f4f7;
  font-family: Arial, Helvetica, sans-serif;
}
</style>
