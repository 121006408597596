import TagObject from "./TagObject";

export default class DelegationObject {

  name = null;
  email = null;
  tag = null;
  startsOn = new Date();
  expireOn = new Date();
  
  user = null;

  constructor(delegation) {
    if (delegation) {
      this.name = delegation.name;
      this.email = delegation.email;
      this.tag = new TagObject(delegation.tag.container, delegation.tag.name),
      this.startsOn = new Date(delegation.startsOn),
      this.expireOn = new Date(delegation.expireOn),

      this.user = { name: this.name, email: this.email }
    }
  }

  toRequest() {
    return {
      Email: this.email,
      Tag: this.tag,
      StartsOn: this.startsOn,
      ExpireOn: this.expireOn
    }
  }

  toDummyResponse() {
    return {
      name: this.name,
      email: this.email,
      tag: this.tag,
      startsOn: this.startsOn,
      expireOn: this.expireOn
    }
  }
}