export const SET_DATA = "SET_DATA";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_INIT = "SET_INIT";
export const SET_DATA_TOKEN = "SET_DATA_TOKEN";
export const SET_DATA_ACCOUNT = "SET_DATA_ACCOUNT";
export const SET_ERROR = "SET_ERROR";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_REFRESH_TOKEN_TIMER = "SET_REFRESH_TOKEN_TIMER";
export const SET_DATA_ACCOUNT_VUEX = "SET_DATA_ACCOUNT_VUEX";

export const SET_SETTINGS = "SET_SETTINGS";
export const SET_OFFSET = "SET_OFFSET";
export const SET_DAYS = "SET_DAYS";
