export default class AccessSettingObject {

  days = 0;

  constructor(access) {
    if (access) {
      this.days = access.days;
    }
  }

  toRequest() {
    return {
      Days: Number(this.days)
    }
  }
}