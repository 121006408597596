import Vue from "../main";

import NotificationModalView from "../components/modals/NotificationModalView.vue";

export default {
  showNotification(
    title,
    text,
    additionalText,
    additionalTextUrl,
    additionalTextCode
  ) {
    return new Promise((resolve, reject) => {
      Vue.$modal.show(
        NotificationModalView,
        {
          title: title,
          text: text,
          additionalText: additionalText,
          additionalTextUrl: additionalTextUrl,
          additionalTextCode: additionalTextCode,
          okHandler: () => {
            Vue.$modal.hide("NotificationModalView", { closedByButton: true });
            resolve();
          },
        },
        {
          name: "NotificationModalView",
          height: "auto",
        },
        {
          "before-close": (event) => {
            if (!event.params || !event.params.closedByButton) reject();
          },
        }
      );
    });
  },
};
