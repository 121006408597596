import KeyObject from "./KeyObject";

export default class ContainerObject {

  id = null;
  name = null;
  size = 0;
  files = 0;
  keys = [];

  constructor(container = null) {
    if (container) {
      this.id = container.id;
      this.name = container.name;
      this.size = container.size;
      this.files = container.files;
      this.keys = container.keys.map(k => new KeyObject(k));
    }
  }

  toRequest() {
    return {
      Id: this.id,
      Name: this.name,
      Keys: this.keys.map(k => k.key)
    }
  }

  toDummyResponse() {
    return {
      id: this.id,
      name: this.name,
      size: this.size,
      files: this.files,
      keys: this.keys.map(k => k.key)
    }
  }
}