<template>
  <div>
    <v-app class="modal-wrapper">
      <v-card class="modal-content">
        <v-card-title class="title">
          {{ title }}
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          {{ text }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text v-if="additionalText">
          <v-card-text v-if="additionalTextUrl" class="pt-1 pb-1">{{
            additionalTextUrl
          }}</v-card-text>
          <v-card-text v-if="additionalTextCode" class="pt-1 pb-1">{{
            additionalTextCode
          }}</v-card-text>
          <v-card-text v-if="additionalText" class="pt-1 pb-1">{{
            additionalText
          }}</v-card-text>
        </v-card-text>
        <v-divider v-if="additionalText"></v-divider>
        
        <v-card-actions class="buttons pt-3 pb-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="onOkClicked"> Ок </v-btn>
        </v-card-actions>
      </v-card>
    </v-app>
  </div>
</template>

<script>
import "@/assets/styles/modals.css";

export default {
  name: "NotificationModalView",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    additionalTextUrl: {
      type: String,
      default: "",
    },
    additionalTextCode: {
      type: String,
      default: "",
    },
    additionalText: {
      type: String,
      default: "",
    },
    okHandler: {
      type: Function,
      default: null,
    },
    cancelHandler: {
      type: Function,
      default: null,
    },
  },

  methods: {
    onOkClicked() {
      this.okHandler();
    },
  },
};
</script>
