export default {

  roles: [
    { value: "Admin", title: "Администратор" },
    { value: "Auditor", title: "Аудитор" },
    { value: "User", title: "Сотрудник" }
  ],

  accessTypes: [
    { value: "Anon", title: "Анонимно" },
    { value: "Pin", title: "По пин-коду" },
    { value: "User", title: "Сотруднику" }
  ],

  eventTypes: [
    { value: "Create", title: "Создание" },
    { value: "Read", title: "Чтение" },
    { value: "Update", title: "Обновление" },
    { value: "Delete", title: "Удаление" }
  ],

  objectTypes: [
    { value: "Container", title: "Контейнер" },
    { value: "Tag", title: "Тег" },
    { value: "File", title: "Файл" },
    { value: "User", title: "Пользователь" },
    { value: "Settings", title: "Настройки" },
    { value: "Other", title: "Прочее" }
  ],

  retentionDates: [
    { value: 30, title: "30 дней" },
    { value: 60, title: "60 дней" },
    { value: 90, title: "90 дней" },
    { value: 120, title: "120 дней" },
    { value: 180, title: "180 дней" },
    { value: 270, title: "270 дней" },
    { value: 365, title: "365 дней" },
    { value: 550, title: "550 дней" },
    { value: 730, title: "730 дней" }
  ]

}