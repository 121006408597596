export default class FileAccessObject {

  name = null;
  email = null;
  startsOn = new Date();
  expireOn = new Date();

  constructor(access) {
    if (access) {
      this.email = access.email;
      this.name = access.name;
      this.startsOn = new Date(access.startsOn);
      this.expireOn = new Date(access.expireOn);
    }
  }

  toRequest() {
    return {
      Email: this.email,
      StartsOn: this.startsOn,
      ExpireOn: this.expireOn
    }
  }

  toDummyResponse() {
    return {
      name: this.name,
      email: this.email,
      startsOn: this.startsOn,
      expireOn: this.expireOn
    }
  }
}