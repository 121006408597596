<template>
  <v-container>
    
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Настройки</h2>
      </v-col>
    </v-row>

    <state-container ref="main">
      <v-container class="full-container">
        <v-row class="mt-10">
          <v-col style="text-align: start">
            <h3>Правила переноса файлов в архивное хранилище:</h3>
            <div style="display: inline-grid">
              <span v-for="(item, i) in settings.archive.length > 10 ? 10 : settings.archive.length" :key="i"
                    class="ml-3">
                {{ i + 1 }}. Контейнер {{ settings.archive[i].tag.container }}, тег {{ settings.archive[i].tag.name }} - архивировать файлы старше {{ settings.archive[i].days }} дней
              </span>
              <v-btn v-if="settings.archive.length > 10 && isAdmin"
                     class="mt-2"
                     color="primary"
                     @click="onArchiveEditClicked">
                Смотреть все правила
              </v-btn>
            </div>
          </v-col>
          <v-col cols="4">
            <v-btn v-if="isAdmin"
                   color="primary"
                   @click="onArchiveEditClicked">
              Изменить
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col style="text-align: start">
            <h3>Длительность хранения событий в Yandex DataBase:</h3>
            <span class="ml-3">{{ settings.storage.days }} дней</span>
          </v-col>
          <v-col cols="4">
            <v-btn v-if="isAdmin"
                   color="primary"
                   @click="onStorageEditClicked">
              Изменить
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col style="text-align: start">
            <h3>Часовой пояс при отображении времени:</h3>
            <span class="ml-3">{{ timezone }}</span>
          </v-col>
          <v-col cols="4">
            <v-btn v-if="isAdmin"
                   color="primary"
                   @click="onTimezoneEditClicked">
              Изменить
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-10">
          <v-col style="text-align: start">
            <h3>Временной интервал по умолчанию для предоставления доступа:</h3>
            <span class="ml-3">{{ settings.access.days }} дней</span>
          </v-col>
          <v-col cols="4">
            <v-btn v-if="isAdmin"
                   color="primary"
                   @click="onAccessEditClicked">
              Изменить
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </state-container>

    <overlay :value="loading" />
    <custom-snackbar ref="snackbar" />

    <custom-dialog v-model="inArchiveDialog"
                   width="fit-content">
      <v-row>
        <v-col style="text-align: start">
          <h3>Правила переноса файлов в архивное хранилище</h3>
        </v-col>
      </v-row>
  
      <v-row class="mt-4">
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="primary"
                 :disabled="isMetaLoading || isSaving"
                 :loading="isMetaLoading"
                 @click="onArchiveRuleAddClicked">
            Добавить правило
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <custom-table :items="archiveTemp"
                        :items-per-page="10"
                        :headers="headers">

            <!-- eslint-disable-next-line -->
            <template v-slot:item.tag="{ item }">
              {{ item.tag | tag }}
            </template>
  
            <!-- eslint-disable-next-line -->
            <template v-slot:item.actions="{ item }">
              <v-icon @click="onArchiveRuleEditClicked(item)">
                mdi-pencil
              </v-icon>
              <v-icon @click="onArchiveRuleDeleteClicked(item)">
                mdi-delete
              </v-icon>
            </template>
  
          </custom-table>
        </v-col>
      </v-row>
  
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 :disabled="isSaving || isMetaLoading"
                 @click="inArchiveDialog = false">
            Отмена
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 :disabled="isSaving || isMetaLoading"
                 :loading="isSaving"
                 @click="onArchiveSaveClicked">
            Сохранить
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

    <custom-dialog v-model="inArchiveRuleDialog"
                   width="fit-content">
      <v-row>
        <v-col style="text-align: start">
          <h3>Правило переноса</h3>
        </v-col>
      </v-row>

      <v-form @submit.prevent ref="form" lazy-validation>
  
        <v-row class="mt-4">
          <v-col style="display: flex; justify-content: end;">
            <v-autocomplete v-model="archiveRuleTemp.tag"
                            :items="tagsMetaForSelect"
                            :item-text="tagConverter"
                            placeholder="Введите название контейнера или тега для поиска"
                            label="Тег"
                            outlined
                            dense
                            return-object
                            :readonly="isArchiveRuleEdit"
                            required
                            :rules="validationRules.tagRules"
                            class="mb-n4" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field v-model="archiveRuleTemp.days"
                          type="number"
                          label="Архивировать через N дней"
                          :min="1"
                          outlined
                          dense
                          required
                          :rules="validationRules.daysRules" />
          </v-col>
        </v-row>

      </v-form>
  
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 :disabled="isSaving"
                 @click="inArchiveRuleDialog = false">
            Отмена
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 :disabled="isSaving"
                 :loading="isSaving"
                 @click="onArchiveRuleSaveClicked">
            Сохранить
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

    <custom-dialog v-model="inStorageDialog"
                   width="fit-content">
      <v-row>
        <v-col style="text-align: start">
          <h3>Длительность хранения событий в Yandex DataBase</h3>
        </v-col>
      </v-row>
  
      <v-row class="mt-4">
        <v-col>
          <v-select :items="storageDates"
                    v-model="storageTemp.days"
                    item-text="title"
                    item-value="value"
                    label="Выберите период"
                    solo
                    dense
                    hide-details />
        </v-col>
      </v-row>
  
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 :disabled="isSaving"
                 @click="inStorageDialog = false">
            Отмена
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 :disabled="isSaving"
                 :loading="isSaving"
                 @click="onStorageSaveClicked">
            Сохранить
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

    <custom-dialog v-model="inTimezoneDialog"
                   width="fit-content">
      <v-row>
        <v-col style="text-align: start">
          <h3>Часовой пояс при отображении времени</h3>
        </v-col>
      </v-row>
  
      <v-row class="mt-4">
        <v-col>
          <v-select :items="timezones"
                    v-model="timezoneTemp.offset"
                    item-text="title"
                    placeholder="Выберите часовой пояс"
                    solo
                    dense />
        </v-col>
      </v-row>
  
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 :disabled="isSaving"
                 @click="inTimezoneDialog = false">
            Отмена
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 :disabled="isSaving"
                 :loading="isSaving"
                 @click="onTimezoneSaveClicked">
            Сохранить
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

    <custom-dialog v-model="inAccessDialog"
                   width="fit-content">
      <v-row>
        <v-col style="text-align: start">
          <h3>Временной интервал по умолчанию для предоставления доступа</h3>
        </v-col>
      </v-row>
  
      <v-row class="mt-4">
        <v-col>
          <v-text-field v-model="accessTemp.days"
                        type="number"
                        :min="1"
                        outlined
                        dense />
        </v-col>
      </v-row>
  
      <v-row class="mb-1">
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: end;">
          <v-btn color="error"
                 :disabled="isSaving"
                 @click="inAccessDialog = false">
            Отмена
          </v-btn>
        </v-col>
        <v-col style="display: flex; justify-content: start;">
          <v-btn color="primary"
                 :disabled="isSaving"
                 :loading="isSaving"
                 @click="onAccessSaveClicked">
            Сохранить
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </custom-dialog>

  </v-container>
</template>

<script>

import ApiService from "@/services/api"

import Overlay from "@/components/Overlay.vue"
import StateContainer from "@/components/StateContainer.vue"
import Table from "@/components/Table.vue"
import Snackbar from "@/components/SnackBar.vue"
import Dialog from "@/components/Dialog.vue"

import StateMixins from "@/mixins/state"
import ErrorsMixins from "@/mixins/errors"

import Filters from "@/helpers/ui/filters"
import Rules from "@/helpers/validators/settings"

import SettingsObject from "@/store/objects/settings/SettingsObject"
import ArchiveSettingObject from '@/store/objects/settings/ArchiveSettingObject'
import StorageSettingObject from '@/store/objects/settings/StorageSettingObject'
import TimezoneSettingObject from '@/store/objects/settings/TimezoneSettingObject'
import AccessSettingObject from '@/store/objects/settings/AccessSettingObject'
import TagObject from "@/store/objects/settings/TagObject"

import BlConsts from "@/store/consts/bl"

import { SET_OFFSET, SET_DAYS } from "@/store/mutations"

const moment = require('moment-timezone');

export default {
  mixins: [StateMixins, ErrorsMixins],

  computed: {
    isAdmin() {
      return this.$store.getters.role == "Admin";
    },

    timezone() {
      let timezone = this.timezones.find(timezone => timezone.value == this.settings.timezone.offset);
      if (timezone)
        return timezone.title;
      else
        return "";
    },

    tagsMetaForSelect() {
      if (!this.tagsMetaList)
        return [];
      else if (!this.archiveTemp.length || this.isArchiveRuleEdit)
        return this.tagsMetaList;
      else
        return this.tagsMetaList.filter(t => this.archiveTemp.find(a => a.tag.container == t.container && a.tag.name == t.name) == undefined);
    }
  },

  data() {
    let timezones = this.getTimeZones();

    return {
      loading: true,
      inArchiveDialog: false,
      inArchiveRuleDialog: false,
      inStorageDialog: false,
      inTimezoneDialog: false,
      inAccessDialog: false,

      settings: new SettingsObject(),
      archiveTemp: [],
      archiveRuleTemp: new ArchiveSettingObject(),
      storageTemp: new StorageSettingObject(),
      timezoneTemp: new TimezoneSettingObject(),
      accessTemp: new AccessSettingObject(),

      isArchiveRuleEdit: false,

      timezones: timezones,
      storageDates: BlConsts.retentionDates,
      isSaving: false,

      tagsMetaList: null,
      isMetaLoading: false,

      headers: [
        { text: "Основной тег", value: "tag" },
        { text: "Архивировать через", value: "days" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ],

      validationRules: Rules
    }
  },

  mounted() {
    this.loadData();
  },

  methods: {
    getTimeZones() {
      let timezones = this.groupTimeZones(moment.tz.zonesForCountry("Ru", true)).sort((a, b) => b.offset - a.offset).map(z => {
        let names = z.names.map(name => name.split("/")[1]);
        let offset = z.offset *= -1;

        let half = offset % 60;
        let hour = offset / 60;

        return {
          title: `UTC${offset < 0 ? "-" : "+"}${hour}:${half ? "30" : "00"} (${names.join(", ")})`,
          value: offset
        }
      });

      return timezones;
    },

    groupTimeZones(timezones) {
      const offsets = Array.from(new Set(timezones.map(item => item.offset)));
      return offsets.map(item => {
        return {
          offset: item,
          names: timezones.filter(timezone => timezone.offset === item).map(timezone => timezone.name)
        };
      });
    },

    loadData() {
      ApiService.settings.getAll()
        .then(response => {
          this.settings = new SettingsObject(response.data);
          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        });
    },

    loadMeta(edit, item) {
      if (!this.tagsMetaList) {
        this.isMetaLoading = true;
        ApiService.tags.getMainTagsNames()
          .then(response => {
            this.tagsMetaList = response.data.map(t => new TagObject(t.container, t.name, t.id));
            if (edit)
              this.openEditRuleModal(item);
            else
              this.openCreateRuleModal();
          })
          .catch(error => {
            this.showError(error.response.data.error);
            this.isMetaLoading = false;
          });
      }
    },

    onArchiveEditClicked() {
      this.inArchiveDialog = true;
      this.archiveTemp = this.settings.archive.map(a => new ArchiveSettingObject(a));
    },

    onArchiveSaveClicked() {
      this.isSaving = true;
      let body = this.archiveTemp.map(a => a.toRequest());
      ApiService.settings.updateArchive(body)
        .then(() => {
          this.settings.archive = this.archiveTemp.map(a => a.toDummyResponse());
          this.isSaving = false;
          this.inArchiveDialog = false;
        })
        .catch(error => {
          this.isSaving = false;
          this.showError(error.response.data.error);
        });
    },

    onArchiveRuleAddClicked() {
      if (!this.tagsMetaList) {
        this.loadMeta(false);
      }
      else {
        this.openCreateRuleModal();
      }
    },

    onArchiveRuleEditClicked(item) {
      if (!this.tagsMetaList) {
        this.loadMeta(true, item);
      }
      else {
        this.openEditRuleModal(item);
      }
    },

    openCreateRuleModal() {
      this.inArchiveRuleDialog = true;
      this.isMetaLoading = false;
      this.isArchiveRuleEdit = false;
      this.archiveRuleTemp = new ArchiveSettingObject();

      if (this.$refs.form)
        this.$refs.form.resetValidation();
    },

    openEditRuleModal(item) {
      this.isMetaLoading = false;
      this.inArchiveRuleDialog = true;
      this.isArchiveRuleEdit = true;
      this.archiveRuleTemp = new ArchiveSettingObject(item);
    },

    onArchiveRuleDeleteClicked(item) {
      let foundIndex = this.archiveTemp.findIndex(a => a.tag.container == item.tag.container && a.tag.name == item.tag.name);
      this.$delete(this.archiveTemp, foundIndex);
    },

    onArchiveRuleSaveClicked() {
      if (!this.$refs.form.validate())
        return;
      
      if (this.isArchiveRuleEdit) {
        let foundIndex = this.archiveTemp.findIndex(a => a.tag.container == this.archiveRuleTemp.tag.container && a.tag.name == this.archiveRuleTemp.tag.name);
        this.$set(this.archiveTemp ,foundIndex ,this.archiveRuleTemp);
        this.inArchiveRuleDialog = false;
      }
      else {
        this.archiveTemp.unshift(this.archiveRuleTemp);
        this.inArchiveRuleDialog = false;
      }
    },

    onStorageEditClicked() {
      this.inStorageDialog = true;
      this.storageTemp = new StorageSettingObject(this.settings.storage);
    },

    onStorageSaveClicked() {
      this.isSaving = true;
      let body = this.storageTemp.toRequest();
      ApiService.settings.updateStorage(body)
        .then(() => {
          this.settings.storage = this.storageTemp;
          this.isSaving = false;
          this.inStorageDialog = false;
        })
        .catch(error => {
          this.isSaving = false;
          if (error.response)
            this.showError(error.response.data.error);
          else
            this.showError(error);
          console.log(error)
        })
    },

    onTimezoneEditClicked() {
      this.inTimezoneDialog = true;
      this.timezoneTemp = new TimezoneSettingObject(this.settings.timezone);
    },

    onTimezoneSaveClicked() {
      this.isSaving = true;
      let body = this.timezoneTemp.toRequest();
      ApiService.settings.updateTimezone(body)
        .then(() => {
          this.settings.timezone = this.timezoneTemp;
          this.isSaving = false;
          this.inTimezoneDialog = false;
          this.$store.commit(SET_OFFSET, this.timezoneTemp.offset);
        })
        .catch(error => {
          this.isSaving = false;
          this.showError(error.response.data.error);
        });
    },

    onAccessEditClicked() {
      this.inAccessDialog = true;
      this.accessTemp = new AccessSettingObject(this.settings.access);
    },

    onAccessSaveClicked() {
      this.isSaving = true;
      let body = this.accessTemp.toRequest();
      ApiService.settings.updateAccess(body)
        .then(() => {
          this.settings.access = this.accessTemp;
          this.isSaving = false;
          this.inAccessDialog = false;
          this.$store.commit(SET_DAYS, Number(this.accessTemp.days));
        })
        .catch(error => {
          this.isSaving = false;
          this.showError(error.response.data.error);
        });
    },

    onDialogClosed() {
      if (!this.inArchiveRuleDialog)
        this.$refs.form.resetValidation();
    },

    tagConverter: function(tag) {
      return Filters.tag(tag);
    }
  },

  filters: {
    tag: Filters.tag
  },

  watch: {
    inArchiveRuleDialog: 'onDialogClosed'
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table,
    CustomSnackbar: Snackbar,
    CustomDialog: Dialog
  }
};
</script>
