import Vue from "vue";
import store from "@/store/index";

const setHeader = async () => {
  let token = store.getters.dataToken.access_token;
  Vue.axios.defaults.headers.common["Authorization"] = token ? `Bearer ${token}` : null;
}

export default {

  async get(url) {
    await setHeader();
    return Vue.axios.get(url);
  },

  async post(url, body) {
    await setHeader();
    return Vue.axios.post(url, body);
  },

  async put(url, body) {
    await setHeader();
    return Vue.axios.put(url, body);
  },

  async delete(url) {
    await setHeader();
    return Vue.axios.delete(url);
  },
};
