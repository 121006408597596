export default class KeyObject {

  key = null;
  secret = null;
  showed = false;

  constructor(key, secret = null) {
    this.key = key;
    this.secret = secret;
  }
}