const PrettyBytes = require('pretty-bytes');
import UiConsts from "@/store/consts/ui"
import moment from "moment";
import store from "@/store";

export default {
  bytes: function(bytes) {
    return PrettyBytes(bytes);
  },

  storages: function(tag) {
    if (tag.s3Objects) {
      return "Yandex";
    } else {
      return "-";
    }
  },

  containers: function(containers) {
    if (!containers || !containers.length) {
      return "-";
    }
    else {
      if (containers.length <= 3) {
        return containers.slice(0, 3).join("\n");
      }
      else {
        let str = containers.slice(0, 2).join("\n");
        str = str + "\n+ " + (containers.length - 2);
        return str;
      }
    }
  },

  roles: function(role) {
    let roleObj = UiConsts.roles.find(r => r.key == role);
    if (!roleObj)
      return "-";
    else
      return roleObj.value;
  },

  dateTime: function(date) {
    if (!date)
      return "-";
      
    return moment(date).utcOffset(store.getters.offset).format("DD.MM.YYYY[,] HH:mm:ss");
  },

  date: function(date) {
    if (!date)
      return "-";

    return moment(date).utcOffset(store.getters.offset).format("DD.MM.YYYY");
  },

  tag: function(tag) {
    return `${tag.container}/${tag.name}`;
  }
}