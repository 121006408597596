import ContainersView from "../../views/admin/ContainersView.vue";
import TagsView from "../../views/admin/TagsView.vue";
import FilesView from "../../views/admin/FilesView.vue";
import UsersView from "../../views/admin/UsersView.vue";
import JournalView from "../../views/admin/JournalView.vue";
import SettingsView from "../../views/admin/SettingsView.vue";
import TasksView from "../../views/admin/TasksView.vue";
import DocumentationView from "../../views/admin/DocumentationView.vue";

export default [
  {
    path: "/",
    component: ContainersView,
    name: "Containers",
    meta: {
      layout: "AdminLayout",
      title: "Containers",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Tags",
    component: TagsView,
    name: "Tags",
    meta: {
      layout: "AdminLayout",
      title: "Tags",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Files",
    component: FilesView,
    name: "Files",
    meta: {
      layout: "AdminLayout",
      title: "Files",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Users",
    component: UsersView,
    name: "Users",
    props: true,
    meta: {
      layout: "AdminLayout",
      title: "Users",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Journal",
    component: JournalView,
    name: "Journal",
    meta: {
      layout: "AdminLayout",
      title: "Journal",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Settings",
    component: SettingsView,
    name: "Settings",
    meta: {
      layout: "AdminLayout",
      title: "Settings",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Tasks",
    component: TasksView,
    name: "Tasks",
    meta: {
      layout: "AdminLayout",
      title: "Tasks",
      roles: ["Admin", "Auditor"],
    },
  },
  {
    path: "/Documentation",
    component: DocumentationView,
    name: "Documentation",
    meta: {
      layout: "AdminLayout",
      title: "Documentation",
      roles: ["Admin", "Auditor"],
    },
  },
];
