import LoginView from "../../views/login/LoginView.vue"

export default [
  {
    path: "/login",
    component: LoginView,
    name: "Login",
    meta: {
      layout: "LoginLayout",
      title: "Login",
      roles: ["Admin", "Auditor"]
    }
  }
]