import Vue from "vue";
import VueRouter from "vue-router";

import LoginRoutes from "./routes/login.routes";
import AdminRoutes from "./routes/admin.routes";

import store from "../store/index";
import AuthServiceADFS from "@/services/authADFS";

Vue.use(VueRouter);

const routes = [...LoginRoutes, ...AdminRoutes];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || "Irkutsk Oil Admin";

    if (store.getters.isAuthorized && to.name == "Login")
      router.push({ path: "/", replace: true, name: "Containers" });
  });
});

router.beforeEach((to, from, next) => {
  const checkRouting = function () {
    if (!store.getters.isAuthorized && to.name != "Login")
      next({ path: "/Login", replace: true, name: "Login" });
    else if (store.getters.isAuthorized && to.name == "Login")
      next({ path: "/", replace: true, name: "Containers" });
    else if (!to.meta.roles.includes(store.getters.role)) {
      let route = AdminRoutes.find((r) =>
        r.meta.roles.includes(store.getters.role)
      );
      next(route);
      //TODO: Check route availability
    } else next();
  };

  if (!store.getters.isInitialized && to.name != "Login") {
    AuthServiceADFS.initAccount().then(() => {
      checkRouting();
    });
  } else {
    checkRouting();
  }
});

export default router;
