<template>
  <v-container>
    
    <v-row class="mt-5">
      <v-col style="text-align: start">
        <h2>Журнал</h2>
      </v-col>
    </v-row>

    <v-container class="full-container">
      <v-row class="mt-10">
        <v-col class="container-selector">
          <h4 class="mt-2 mr-5">Событие</h4>
          <v-select :items="eventsList"
                    v-model="event"
                    item-text="title"
                    item-value="value"
                    label="Выберите событие"
                    solo
                    dense
                    hide-details
                    @change="onFilterSelected">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="container-selector">
          <h4 class="mt-2 mr-8">Объект</h4>
          <v-select :items="objectsList"
                    v-model="object"
                    item-text="title"
                    item-value="value"
                    label="Выберите объект"
                    solo
                    dense
                    hide-details
                    @change="onFilterSelected">
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="container-selector">
          <h4 class="mt-1 mr-7">С</h4>
          <VueDatePicker v-model="startDate"
                 format="DD.MM.YYYY"
                 placeholder="Выберите дату"
                 color="#01425e"
                 @onChange="onFilterSelected">

            <template #input-icon>
              <v-icon>
                mdi-calendar-multiple
              </v-icon>
            </template>

          </VueDatePicker>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="container-selector">
          <h4 class="mt-1 mr-4">По</h4>
          <VueDatePicker v-model="endDate"
                 format="DD.MM.YYYY"
                 placeholder="Выберите дату"
                 color="#01425e"
                 @onChange="onFilterSelected">

            <template #input-icon>
              <v-icon>
                mdi-calendar-multiple
              </v-icon>
            </template>

          </VueDatePicker>
        </v-col>
      </v-row>

      <state-container ref="main">
        <v-container class="full-container">
          
          <search-panel placeholder="Поиск по объекту, имени и почте" class="mt-6" @search="onSearch" />
          
          <v-row>
            <v-col>
              <custom-table :items="filteredItems"
                            :headers="headers">

                <!-- eslint-disable-next-line -->
                <template v-slot:item.event="{ item }">
                  {{ item.event | event }}
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.object="{ item }">
                  <div>{{ item.object | object }}:</div>
                  <div>{{ item.value }}</div>
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.user="{ item }">
                  {{ item | user }}
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.timestamp="{ item }">
                  {{ item.timestamp | dateTime }}
                </template>

                <!-- eslint-disable-next-line -->
                <template v-slot:item.actions="{ item }">
                  <v-icon @click="onShowDialogClicked(item)">
                    mdi-file-document-outline
                  </v-icon>
                </template>

              </custom-table>
            </v-col>
          </v-row>
        </v-container>
      </state-container>
    </v-container>

    <overlay :value="loading" />

    <custom-dialog v-model="inDialog"
                   width="fit-content">
      <v-row>
        <v-col style="text-align: start">
          <h3>Детали события</h3>
        </v-col>
      </v-row>
  
      <v-row class="mt-7">
        <v-col style="text-align: start">
          Контейнер: {{ item.container ? item.container : "-" }}
        </v-col>
      </v-row>

      <v-row class="mt-1">
        <v-col style="text-align: start">
          Объект: {{ item.object | object }} - {{ item.value }}
        </v-col>
      </v-row>

      <v-row class="mt-1">
        <v-col style="text-align: start">
          Событие: {{ item.event | event }}, {{ item.timestamp | dateTime }}
        </v-col>
      </v-row>

      <v-row class="mt-1 mb-2">
        <v-col style="text-align: start">
          Пользователь: {{ item.username }}, {{ item.upn }}
        </v-col>
      </v-row>
    </custom-dialog>

  </v-container>
</template>

<script>
import "@/assets/styles/pages.css"

import ApiService from "@/services/api"

import Overlay from "@/components/Overlay.vue"
import StateContainer from "@/components/StateContainer.vue"
import Table from "@/components/Table.vue"
import Dialog from "@/components/Dialog.vue"
import SearchPanel from "@/components/SearchPanel.vue"

import { VueDatePicker } from '@mathieustan/vue-datepicker';

import Filters from "@/helpers/ui/filters"

import StateMixins from "@/mixins/state"

import BlConsts from "@/store/consts/bl"
import UiConsts from "@/store/consts/ui"

import store from "@/store/index"

export default {
  mixins: [StateMixins],

  computed: {
    filteredItems() {
      if (!this.searchQuery)
        return this.list;

      let regExp = new RegExp(this.searchQuery.toLocaleLowerCase());

      return this.list.filter(
        (e) => 
          (e.upn && regExp.test(e.upn.toLocaleLowerCase()) != 0) ||
          (e.username && regExp.test(e.username.toLocaleLowerCase()) != 0) ||
          (e.value && regExp.test(e.value.toLocaleLowerCase()) != 0))
    }
  },

  data() {
    let timeZoneDelta = store.getters.offset / 60;

    let startDate = new Date();
    startDate.setDate(new Date().getDate() - 2);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date();
    endDate.setDate(new Date().getDate());
    endDate.setHours(23, 59, 59, 0);

    return {
      loading: false,
      searchQuery: null,
      headers: [
        { text: "Событие", value: "event", sortable: false },
        { text: "Объект", value: "object", sortable: false },
        { text: "Пользователь", value: "user", sortable: false },
        { text: "Дата", value: "timestamp" },
        { text: "", value: "actions", sortable: false, align: "end" }
      ],

      eventsList: BlConsts.eventTypes,
      objectsList: BlConsts.objectTypes,
      event: null,
      object: null,
      startDate: startDate,
      endDate: endDate,
      timeZoneDelta: timeZoneDelta,

      list: [],

      inDialog: false,
      item: {}
    }
  },

  mounted() {
    this.setEmptyState("main");
  },

  methods: {
    onFilterSelected() {
      if (!this.event || !this.object)
        return;

      this.loading = true;

      let startDateBuff = new Date(this.startDate);
      let endDateBuff = new Date(this.endDate);

      startDateBuff.setHours(this.timeZoneDelta, 0, 0, 0);
      endDateBuff.setHours(23 + this.timeZoneDelta, 59, 59, 0);

      let startDateForResponse = new Date(startDateBuff).toISOString();
      let endDateForResponse = new Date(endDateBuff).toISOString();

      ApiService.journal.getEvents(this.event, this.object, startDateForResponse, endDateForResponse)
        .then(response => {
          if (!response.data.length) {
            this.setNoDataState("main");
          }
          else {
            this.list = response.data;
            this.setBaseState("main");
          }

          this.loading = false;
        })
        .catch(error => {
          this.setErrorState("main", error);
          this.loading = false;
          console.log(error);
        });
    },

    onShowDialogClicked(item) {
      this.item = item;
      this.inDialog = true;
    },

    onSearch(search) {
      this.searchQuery = search;
    }
  },

  filters: {
    event: function(event) {
      let item = UiConsts.eventTypes.find(e => e.value == event);
      if (!item)
        return "-";
      
      return item.title;
    },
    object: function(object) {
      let item = UiConsts.objectTypes.find(e => e.value == object);
      if (!item)
        return "-";
      
      return item.title;
    },
    user: function(item) {
      if (!item)
        return "-";

      return `${item.username} (${item.upn ? item.upn.split("@")[0] : ""})`;
    },
    date: Filters.date,
    dateTime: Filters.dateTime
  },

  components: {
    Overlay,
    StateContainer,
    CustomTable: Table,
    CustomDialog: Dialog,
    VueDatePicker,
    SearchPanel
  }
};
</script>
