export default class VersionObject {

  version = null;
  name = null;
  createdAt = null;
  size = 0;
  link = null;

  constructor(version) {
    if (version) {
      this.version = version.version;
      this.name = version.name;
      this.createdAt = version.createdAt;
      this.size = version.size;
      this.link = version.link;
    }
  }

  toDummyResponse() {
    return {
      version: this.version,
      name: this.name,
      createdAt: this.createdAt,
      size: this.size,
      link: this.link
    }
  }
}