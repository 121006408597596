<template>
  <v-container class="login-view-container">
    <div class="login-logo-container">
      <v-img :src="require('@/assets/svg/logo.svg')" contain class="logo" />
      <h3 class="logo-title">
        Облачное файловое хранилище <br />
        Панель администратора
      </h3>
    </div>

    <v-btn color="primary" @click="onLoginClicked" class="login-button">
      Войти
    </v-btn>

    <overlay :value="loading" />
  </v-container>
</template>

<script>
import AuthServiceADFS from "@/services/authADFS";
import EventBus from "@/main";
import Overlay from "@/components/Overlay.vue";
import store from "@/store/index";

export default {
  computed: {
    loading() {
      return store.getters.dataToken.access_token && !store.getters.isAuthorized && !store.getters.isError ? true : false;
    },
  },

  methods: {
    onLoginClicked() {
      AuthServiceADFS.login();
    },
  },

  beforeCreate() {
    EventBus.$once("LOGGED", () => {
      this.$router.push("/");
    });
  },

  beforeDestroy() {
    EventBus.$off("LOGGED");
  },

  components: {
    Overlay,
  },
};
</script>
