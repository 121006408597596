export default {

  roles: [
    { key: "Admin", value: "Администратор" },
    { key: "Auditor", value: "Аудитор" },
    { key: "User", value: "Сотрудник" }
  ],

  eventTypes: [
    { value: 0, title: "Создание" },
    { value: 1, title: "Чтение" },
    { value: 2, title: "Обновление" },
    { value: 3, title: "Удаление" }
  ],

  objectTypes: [
    { value: 1, title: "Контейнер" },
    { value: 2, title: "Тег" },
    { value: 3, title: "Файл" },
    { value: 4, title: "Пользователь" },
    { value: 5, title: "Настройки" },
    { value: 0, title: "Прочее" }
  ]

}