import TagObject from "./TagObject";

export default class ArchiveSettingObject {

  days = 1;
  tag = null;

  constructor(archive) {
    if (archive) {
      this.days = archive.days;
      this.tag = new TagObject(archive.tag.container, archive.tag.name, archive.tag.id);
    }
  }

  toRequest() {
    return {
      Days: Number(this.days),
      Tag: this.tag.toRequest()
    }
  }

  toDummyResponse() {
    return {
      days: this.days,
      tag: this.tag.toDummyResponse()
    }
  }
}