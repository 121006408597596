export default class TagObject {

  id = null;
  name = null;
  container = null;

  constructor(container = null, name = null, id = null) {
    this.id = id;
    this.name = name;
    this.container = container;
  }

  toRequest() {
    return {
      Id: this.id,
      Name: this.name,
      Container: this.container
    }
  }

  toDummyResponse() {
    return {
      id: this.tagId,
      name: this.name,
      container: this.container
    }
  }
}