<template>
  <v-dialog v-model="dialog"
            :width="width"
            overlay-color="var(--background-color)"
            overlay-opacity="0.8"
            @input="onDialogChanged">
    <v-card class="dialog-card">
      <v-card-text class="dialog-card-text">
        <v-row id="close-panel" class="pt-6">
          <v-spacer></v-spacer>
            <v-btn @click="onCloseClicked"
                   icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>

        <slot />
        
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import "@/assets/styles/components.css"

export default {
  model: {
    prop: "value",
    event: "change"
  },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      dialog: this.value
    }
  },

  methods: {
    onCloseClicked() {
      this.$emit("change", false);
    },

    onDialogChanged() {
      this.$emit("change", false);
    }
  },

  watch: {
    'value': function(value) {
      let panel = document.getElementById('close-panel');
      if (panel)
        panel.scrollIntoView();

      this.dialog = value;
    }
  }

}
</script>